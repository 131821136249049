<template>
  <v-container fluid>
    <v-layout row>
      <v-flex xs2 md1>
        <v-select
          v-model="searchTypes"
          :items="Search"
          flat
          item-text="status"
          single-line
          class="pt-0"
          item-value="abbr"
          persistent-hint
          disabled
          height="10"
          return-object
          outline
        ></v-select>
      </v-flex>
      <v-flex xs2 md3>
        <v-text-field
          height="55px"
          class="gap"
          label="Search here.."
          outline
          single-line
          v-model="search"
          type="number"
          @keyup.enter="searchAnything"
          @click:append="searchAnything"
          append-icon="search"
        ></v-text-field>
      </v-flex>
      <v-flex xs1></v-flex>
      <v-flex xs2 md2>
        <v-select
          v-model="select"
          :items="jobStatus"
          item-text="status"
          single-line
          item-value="abbr"
          label="Status Filter"
          return-object
          height="10"
          outline
          @change="filterByJob"
        ></v-select>
      </v-flex>
      <!-- 
      <v-flex xs1 md1 class="reset" style="text-align: end;">
        <v-btn
          class="reset-btn-color m-t-0"
          flat
          prepend-icon
          color="orange darken-1"
          @click.native="reset()"
        >
          Reset
          <v-icon color="orange">donut_large</v-icon>
        </v-btn>
      </v-flex> -->
      <v-flex xs1 md7 class="reset" style="text-align: end">
        <v-btn
          class="reset-btn-color m-t-0"
          flat
          prepend-icon
          color="orange darken-1"
          @click.native="reset()"
        >
          Reset
          <v-icon color="orange">donut_large</v-icon>
        </v-btn>
        <!-- <v-btn
          class="reset-btn-color m-t-0"
          flat
          prepend-icon
          color="orange darken-1"
          target="_blank"
          v-bind:href="'https://mixpanel.com/report/2201728'"
          >MIX PANEL</v-btn
        > -->
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import moment from "moment";
import Loading from "vue-loading-overlay";
import { StorageKeys } from "../../constants/constants";
import { EventBus } from "../../event-bus.js";
export default {
  data() {
    return {
      select: { status: "All", abbr: "All" },
      searchTypes: { status: "job Id", abbr: "jobId" },

      jobStatus: [
        { status: "All", abbr: "All" },
        { status: "Open", abbr: "Open" },
        { status: "Closed", abbr: "Closed" },
      ],
      Search: [{ status: "Job Id", abbr: "jobId" }],
      job: "",
      search: "",
    };
  },
  created() {
    this.$emit("job-Filter", this.select.abbr);
    EventBus.$on("countryChanged", (val) => {
      //this.reset();
    });
  },
  methods: {
    reset() {
      localStorage.removeItem("tenderJobFilter");
      localStorage.removeItem("tenderSearch");
      this.$emit("customer-reset", this.search);
      this.select = { status: "All", abbr: "All" };
      this.search = "";
    },

    filterByJob() {
      localStorage.setItem("tenderJobFilter", this.select.abbr);
      this.$emit("job-Filter", this.select.abbr);
    },

    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    searchAnything() {
      // localStorage.setItem("tenderSearch", this.search);
      this.$emit("customer-search", this.search);
      this.$emit("filter-type", this.select.abbr);
    },
  },
};
</script>
<style scoped>
.search {
  float: left;
}

.country {
  display: inline-block;
}

.filter {
  padding: 20px;
  margin: 0 auto;
}
</style>
