<template>
  <div class="log pl-2">
    <button
      target="_blank"
      type="button"
      class="track-ass-btn m-t-10 m-l-10 green-button"
      title="Details"
      :disabled="loading"
      @click.stop="check()"
    >
      Withdraw
    </button>
    <!-- <a class="dropdowns-content" @click.stop="check()">Withdraw</a> -->

    <v-dialog v-model="dialog3" max-width="28%">
      <v-form ref="form">
        <v-card class="comments-view">
          <v-toolbar fixed flat style="background-color: #fee9d0">
            <v-spacer></v-spacer>
            <v-toolbar-title>Withdraw Job</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.prevent="dialog3 = false">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-spacer class="address-pop">
            <v-card-text class="nospace">
              <v-layout pb-2>
                <v-flex xs12 md12>
                  <div class="subheading">
                    BID MODEL -
                    <span style="color: black">Bid</span>
                  </div>
                </v-flex>
                <v-spacer />
              </v-layout>
              <v-layout pb-2>
                <v-flex xs12 md12>
                  <div class="subheading" style="color: lightgrey">
                    Start Date - {{ getTimes(detail.startDate) }},
                    {{ detail.pickupTime }}
                  </div>
                </v-flex>
                <v-spacer />
              </v-layout>
              <v-layout pb-2>
                <v-flex xs12 md12>
                  <div class="subheading" style="color: lightgrey">
                    Expiry Date - {{ getTime(detail.closedDate) }}
                  </div>
                </v-flex>
                <v-spacer />
              </v-layout>
              <v-layout pb-2>
                <v-flex xs12 md12>
                  <div class="subheading">
                    Job Price: {{ detail.jobPrice }}
                    {{ detail.transporterCurrency }}
                  </div>
                </v-flex>
                <v-spacer />
              </v-layout>
              <v-layout pb-2>
                <v-flex xs12 md12>
                  <div class="subheading" v-if="detail.amountForTransporter">
                    Bid Price: {{ detail.amountForTransporter }}
                    {{ detail.transporterCurrency }}
                  </div>
                  <div class="subheading" v-else>Bid Price: N.A</div>
                </v-flex>
                <v-spacer />
              </v-layout>
              <v-layout pb-2>
                <v-flex xs12 md12>
                  <div class="subheading">
                    Truck Required: {{ detail.requiredTrucks }}
                  </div>
                </v-flex>
                <v-spacer />
              </v-layout>
              <v-layout pb-2>
                <v-flex xs12 md12>
                  <div class="subheading">
                    Truck Remaining: {{ detail.remainingTruck }}
                  </div>
                </v-flex>
                <v-spacer />
              </v-layout>
              <v-layout pt-3 style="text-align: center">
                <v-flex xs12 md12>
                  <v-btn
                    color="orange darken-1"
                    class="px-2 white--text"
                    style="text-transform: none; !important"
                    @click="open(detail)"
                    >Confirm</v-btn
                  >
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-snackbar
              :timeout="6000"
              bottom
              color="red darken-2"
              v-model="x.error"
              class="white--text"
              v-if="x.error"
              >{{ x.error }}</v-snackbar
            >
          </v-spacer>

          <v-dialog
            v-model="dialog4"
            persistent
            :max-width="options.width"
            :style="{ zIndex: options.zIndex }"
            @keydown.esc="cancel"
          >
            <v-card>
              <v-toolbar dark :color="options.color" dense flat>
                <v-toolbar-title class="white--text">{{
                  title
                }}</v-toolbar-title>
              </v-toolbar>
              <v-card-text
                style="
                  text-align: center;
                  padding: 16px 12px 16px 12px !important;
                "
                v-show="!!message"
                class="pa-4"
              >
                <div v-if="errorMessage" class="subheading">
                  {{ errorMessage }}
                </div>
                <div class="subheading">{{ message }}</div>
              </v-card-text>
              <v-card-actions class="pt-0 justfy_c">
                <v-btn
                  small
                  style="text-transform: none; !important"
                  color="grey"
                  text
                  @click.native="cancel"
                  >No</v-btn
                >
                <v-btn
                  small
                  style="text-transform: none; !important"
                  color="orange"
                  darken-1
                  text
                  @click.native="agree"
                  >Yes</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
import { StorageKeys } from "../../constants/constants";
import { tenderSectionApiUrl } from "@/constants/api-urls.js";
import ErrorBox from "@/components/Common/ErrorBox";
export default {
  name: "WithDrawJobs",
  created() {},
  data() {
    return {
      operation: "",
      operationName: "",
      id: "",
      x: {
        error: null,
      },
      options: {
        color: "#ffc04c",
        width: 450,
        zIndex: 200,
      },
      items: [],

      title: null,
      message: null,
      errorMessage: null,
      cancelTitle: null,
      cancelMessage: null,

      cancelComments: "",
      processing: false,
      reason: "",
      dialog3: false,
      dialog4: false,
      disStatus: false,
      loading: false,
      rules: {
        required: (value) => !!value || "This field is required.",
      },
    };
  },
  props: {
    detail: Object,
  },

  methods: {
    async check() {
      this.loading = true;
      this.operationName = "withdraw-job";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.dialog3 = true;
        this.loading = false;
      } else {
        this.loading = false;
        return;
      }
    },
    getTime3(date) {
      return moment.utc(date).format("DD/MM/YYYY");
    },
    getTimes(time) {
      return moment.unix(time).format("DD/MM/YYYY");
    },
    getTime(date) {
      return moment(date).format("DD/MM/YYYY, HH:mm");
    },
    open(val) {
      this.errorMessage = null;
      this.message = null;
      if (!navigator.onLine) {
        this.loading = false;
        this.x.error = "Please check your internet connection";
        setTimeout(() => {
          this.x.error = "";
        }, 3000);
        return;
      }

      this.id = val.jobId;
      if (val.obtainedTrucks != val.requiredTrucks) {
        this.errorMessage =
          "Withdrawing this job will remove job from transporter.";
        this.message = "Are you sure want to withdraw this job?";
      } else {
        this.message = "Are you sure want to withdraw this job?";
      }
      this.dialog4 = true;
      this.dialog3 = false;
      this.title = "Withdraw Job";

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);

      this.withdraw(this.id);
      this.dialog4 = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog4 = false;
    },
    async withdraw(value) {
      this.operationName = "withdraw-job";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.loading = true;
        let url = tenderSectionApiUrl.withDrawBid;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };

        let body = {
          jobId: value.toString(),
        };

        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            this.$emit("refreshJobs", this.detail);
            this.dialog4 = false;
            this.loading = false;
          },
          (error) => {
            this.$emit("error", error.response.data.message);
            this.loading = false;

            this.x.error = error.response.data.message;
          }
        );
      } else {
        return;
      }
    },
  },
};
</script>
<style scoped>
.dropdowns-content a {
  color: black;
  padding: 5px 5px 5px 5px;
  text-decoration: none;
  display: block;
}

.dropdowns-content a:hover {
  background-color: #ddd;
}

.break_long_text {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.comments-view .v-toolbar {
  width: 100%;
  /* margin-left: 25%; */
}
.v-toolbar--fixed {
  position: inherit;
}
.green-button {
  border-radius: 5px;
  background-color: #63ae63;
  color: #ffffff !important;
  border: 2px solid green !important;
}
</style>
