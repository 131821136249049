<template>
  <div class="log">
    <button
      v-permissions="'view-details-jobs'"
      @click.stop="dialog = false"
      @click="getResponse"
      title="bids"
      target="_blank"
      class="view-ass-btn"
    >
      Bids
    </button>
    <!-- <a class="dropdowns-content" @click="getResponse">Bids</a> -->

    <v-dialog v-model="dialog" max-width="70%" persistent>
      <v-form ref="form" onsubmit="return false;">
        <v-card class="comments-view">
          <v-toolbar fixed flat style="background-color: #fee9d0">
            <v-spacer></v-spacer>
            <v-toolbar-title class="pd5">View Bid Response</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-btn icon @click.prevent="dialog = false">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-layout pt-3>
            <v-flex xs6 md6 class="timer-style"
              >Job Id - {{ bid.jobId }}
            </v-flex>

            <v-flex xs4 md6>
              <vue-countdown-timer
                class="timer-style"
                @start_callback="startCallBack('event started')"
                @end_callback="endCallBack('event ended')"
                :start-time="this.currentDate"
                :end-time="this.bid.closedDate"
                :interval="1000"
                :start-label="'Expires in:'"
                :end-label="'Expires in'"
                label-position="begin"
                :end-text="'Bid is closed'"
                :day-txt="'Days/Day'"
                :hour-txt="'    : '"
                :minutes-txt="': '"
                :seconds-txt="'(HH:MM:SS)'"
              >
                <template slot="countdown" slot-scope="scope">
                  <span>{{ scope.props.days }}</span>
                  <i v-if="scope.props.days > 1">days</i>
                  <i v-else>day</i>
                  <span>{{ scope.props.hours }}</span>
                  <i>{{ scope.props.hourTxt }}</i>
                  <span>{{ scope.props.minutes }}</span>
                  <i>{{ scope.props.minutesTxt }}</i>
                  <span>{{ scope.props.seconds }}</span>
                  <i>{{ scope.props.secondsTxt }}</i>
                </template>
              </vue-countdown-timer>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs6 md6 class="timer-style pt-7" v-if="bid.commentForBid">
              <p>Comments - {{ bid.commentForBid }}</p>
            </v-flex>
          </v-layout>

          <v-progress-linear
            indeterminate
            color="trukkin-theme darken-2"
            v-show="loading"
          ></v-progress-linear>
          <v-spacer class>
            <v-card-text class="nospace">
              <v-data-table
                id="jobsTable"
                hide-actions
                :headers="headers"
                :items="items.list"
              >
                <template slot="items" slot-scope="props">
                  <td>
                    {{ props.item.transporter[0].transporterName }} ({{
                      props.item.transporter[0].transporterId
                    }})
                    <br />

                    {{ props.item.transporter[0].email }}
                    <br />
                    {{ props.item.transporter[0].countryCode }}-{{
                      props.item.transporter[0].phoneNo
                    }}
                  </td>
                  <td>{{ getTime(props.item.updatedAt) }}</td>

                  <td>{{ props.item.amount }} {{ transporterCurrency }}</td>
                  <td>{{ props.item.providedTrucks }}</td>
                  <td>
                    {{ props.item.amount / props.item.providedTrucks }}
                    {{ transporterCurrency }}
                  </td>

                  <td>
                    <v-tooltip bottom v-if="props.item.comments">
                      <template v-slot:activator="{ on }">
                        <span class="fix-width-td" v-on="on">{{
                          props.item.comments
                        }}</span>
                      </template>
                      <span>{{ props.item.comments }}</span>
                    </v-tooltip>
                    <span v-if="!props.item.comments">-</span>
                  </td>
                  <td class>
                    <span
                      class="countss"
                      v-if="showAccept && !props.item.isApproved"
                      >Pending</span
                    >

                    <span
                      class="counts"
                      v-if="!showAccept && !props.item.isApproved"
                      >Rejected</span
                    >

                    <span
                      class="count"
                      v-if="
                        props.item.isApproved || props.item.jobModel == 'Fixed'
                      "
                      >Accepted</span
                    >
                  </td>
                  <!-- <td class> -->
                  <!-- <ApprovalPopup
                      v-if="showAccept && !props.item.isApproved"
                      :message="
                        'Assign the bid to the ' +
                          props.item.transporter[0].transporterName +
                          ' ?'
                      "
                      :confirmationTitle="'Assign Bid'"
                      :cancelBtn="'Cancel'"
                      :confirmBtn="'Assign'"
                      :jobData="props.item"
                      @refresh-list="getResponse()"
                    /> -->
                  <!-- </td> -->
                </template>
              </v-data-table>
            </v-card-text>
          </v-spacer>
        </v-card>
      </v-form>
    </v-dialog>
    <v-snackbar
      :timeout="3000"
      bottom
      color="red darken-2"
      v-model="error"
      class="white--text"
      v-if="error"
      >{{ error }}</v-snackbar
    >
  </div>
</template>
<script>
import Bidpopup from "@/components/Procurement/Bidpopup";
import ErrorBox from "@/components/Common/ErrorBox";
import ApprovalPopup from "@/components/Pop-ups/ApprovalPopup.vue";
import { tenderViewBidList } from "@/constants/datatable-headers.js";
import { sendBidpopupTransporterResponseList } from "@/constants/datatable-headers.js";
import { tenderSectionApiUrl } from "@/constants/api-urls.js";
import moment from "moment";
import { StorageKeys } from "../../constants/constants";

export default {
  name: "Timer",
  components: {
    Bidpopup,
    ErrorBox,
    ApprovalPopup,
  },
  computed: {
    haserror() {
      return this.x.error !== null;
    },
  },
  data() {
    return {
      currentDate: new Date().toLocaleString(),
      //         d :new Date( ),
      //  n: d.getTime(),
      //  months :['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
      //  year : d.getFullYear(),
      //  month :months[d.getMonth()],
      //    date : d.getDate(),
      //  hour : d.getHours(),
      //  min : d.getMinutes(),
      //  sec :d.getSeconds(),
      //  time : date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ,

      //      d : new Date(),
      //  n : d.getTime(),
      error: "",
      transporterCurrency: this.bid.transporterCurrency,

      totalTrucks: this.bid.assignCount,
      amount: this.bid.amount,
      JModel: this.bid.jobModel,
      showAccept: true,

      loading: false,
      x: {
        error: null,
      },
      is_accepted: false,
      items: [],
      processing: false,
      reason: "",
      dialog: false,
      disStatus: false,
      loading: false,
      rules: {
        required: (value) => !!value || "This field is required.",
      },
      headers: sendBidpopupTransporterResponseList,
    };
  },
  props: {
    bid: Object,
  },
  created() {
    this.$emit("response-job", this.bid);
  },

  methods: {
    refresh() {
      this.getResponse();
      this.$emit("refresh-tender-list");
    },
    getTime(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },

    startCallBack: function(x) {
      this.loading = false;
    },
    endCallBack: function(x) {
      this.loading = false;
    },
    getResponse() {
      if (!navigator.onLine) {
        this.loading = false;
        this.error = "Please check your internet connection";
        setTimeout(() => {
          this.error = "";
        }, 3000);
        return;
      }
      this.dialog = true;

      (this.loading = "true"), this.$emit("response-job", this.bid);

      this.dialog = true;
      //   if (this.processing) {
      //     return;
      //   }
      this.processing = true;
      let url = tenderSectionApiUrl.viewBid;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        jobId: this.bid._id,
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.dialog = true;
          this.items = response.data.data;

          this.$emit("response-job", this.bid);

          this.processing = false;
          this.loading = false;
          let showAccept = true;
          let data = response.data.data.list.filter((ele) => {
            return ele.isApproved === true;
          });

          if (data.length) {
            this.showAccept = false;
          }

          // response.data.data.forEach(element => {

          //   if(element.isApproved){
          //     isApprovedTest = true;
          //   }

          // });
        },
        (error) => {
          this.error = "Failed to Fetch Assignments";
          this.processing = false;
          this.loading = false;
        }
      );
    },
  },
};
</script>

<style scoped>
.view-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #c87ffe;
  border: 2px solid indigo;
  padding: 2px 5px;
  border-radius: 5px;
}
.v-dialog {
  overflow-y: auto !important;
}
.break_long_text {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}

.comments-view .v-toolbar {
  width: 100%;

  /* margin-left: 25%; */
}
.v-toolbar--fixed {
  position: inherit;
}
.view-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #c87ffe;
  border: 2px solid indigo;
  padding: 2px 5px;
  border-radius: 5px;
}
.max {
  max-width: 70%;
}
.fix-width-td {
  max-width: 200px;
  word-break: break-word;
}
.count {
  background-color: green;
  color: white !important;
  font-size: 14px !important;

  border-radius: 5px;
}
.dropdowns-content a {
  color: black;
  padding: 5px 5px 5px 5px;
  text-decoration: none;
  display: block;
}

.dropdowns-content a:hover {
  background-color: #ddd;
}

.counts {
  background-color: red;
  color: white !important;
  font-size: 14px !important;
  padding: 0 5px;

  border-radius: 5px;
}
.countss {
  background-color: grey;
  color: white !important;
  font-size: 14px !important;
  padding: 0 5px;

  border-radius: 5px;
}
.flexx {
  display: flex;
}
.pd5 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.pt-7 {
  padding-left: 7px !important;
}
.timer-style {
  word-break: break-word;
  padding-left: 10px;
  font-size: large;
}
.fix-width-td {
  display: block;
  max-width: 200px !important;
  word-break: break-word;
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis !important;
}
</style>
