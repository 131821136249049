<template>
  <v-container fluid>
    <v-card raised v-if="showScreen">
      <loading :active.sync="loading" />
      <v-tabs
        fixed-tabs
        class="m-b-20"
        background-color="darkorange"
        dark
        v-model="changeTab"
      >
        <!-- <v-tab :href="`#Fixed`" @click="filterByTab('Fixed')"
          >Fixed Jobs ({{ this.Fcount }})</v-tab
        > -->
        <v-tab :href="`#Bid`" @click="filterByTab('Bid')"
          >Bid Jobs ({{ this.Bcount }})</v-tab
        >
      </v-tabs>

      <v-flex>
        <CustomFilter
          v-on:customer-reset="resetCustomer"
          v-on:job-Filter="jobFilter"
          v-on:customer-search="searchText"
          v-on:filterType="searchText"
        />
      </v-flex>
      <v-flex>
        <tenderList
          :tabFilter="changeTab"
          :jobFilter="job"
          :searchText="search"
          :reset="reset"
          :loader="loading"
          v-on:on-block="refreshActivity"
          v-on:on-load="startLoad"
          v-on:off-load="stopLoad"
          v-on:fixed-count="fixedCount"
          v-on:bid-count="bidCount"
        />
      </v-flex>
    </v-card>
  </v-container>
</template>

<script>
import Loading from "vue-loading-overlay";
import { EventBus } from "../../event-bus.js";
import checkPermissionsMixin from "@/mixins/checkPermissionsMixin";
import tenderList from "./tenderList";
import Swal from "sweetalert2";
import CustomFilter from "./CustomFilter";
import Bids from "./Bids";
import WithDrawJobs from "./WithDrawJobs";
import ReopenJobs from "./ReopenJobs";
import moment from "moment";

export default {
  mixins: [checkPermissionsMixin],
  created() {
    //this.checkPermission("tender");
    this.$eventBus.$on("close-job-profile", () => {
      this.dialog = false;
    });

    this.$eventBus.$on("on-load", () => {
      this.loading = true;
    });

    this.$eventBus.$on("off-load", () => {
      this.loading = false;
    });
    if (this.$route.params.type) {
      if (this.$route.params.type === "Bid") {
        this.changeTab = "Bid";
      }
    }
    if (this.$route.params.type && this.$route.query.workingCountry) {
      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            this.workingCountry = key.value;
          }
        });
      }

      let key = this.$route.query.workingCountry;
      EventBus.$emit("setCountriesfromUrl", key);
      this.workingCountries.forEach((element, index) => {
        if (element.value === key) {
          element.isSelected = true;
          //  EventBus.$emit("countryChanged", key);
        } else element.isSelected = false;
      });
      localStorage.setItem(
        "workingCountries",
        JSON.stringify(this.workingCountries)
      );

      EventBus.$emit("countryChanged", key);
    }
  },
  mounted() {},
  components: {
    tenderList,
    CustomFilter,
    Loading,
    Bids,
    WithDrawJobs,
    ReopenJobs,
  },

  data: () => ({
    Fcount: "",
    showScreen: true,
    Bcount: "",
    search: "",
    country: "",
    job: "",
    active: "0",
    dialog: false,
    reset: false,
    loading: true,
    changeTab: "Bid",
    workingCountry: "",
    workingCountries: {},
  }),
  methods: {
    filterByTab(value) {
      localStorage.setItem("changeTabTender", value);
      if (value == "Bid") {
        this.$router.push(`/tender/Bid`);
      }
      // this.$router.replace({ name: "tender", query: {type: "Fixed"} })}
      // else {
      //   this.$router.push(`/tender/Bid`);
      //   //this.$router.replace({ name: "tender", query: {type: "Bid"} })
      // }
      this.changeTab = value;
    },
    jobFilter(event) {
      this.job = event;
      this.loading = false;
      this.reset = false;
    },
    fixedCount(value) {
      this.Fcount = value;
    },
    bidCount(value) {
      this.Bcount = value;
    },

    refreshActivity() {
      this.reset = true;
    },
    startLoad() {
      this.loading = true;
    },
    stopLoad() {
      this.loading = false;
    },
    resetCustomer(event) {
      this.reset = true;
      this.search = "";
      this.job = "All";
    },
    searchText(event) {
      this.search = event;
      this.reset = false;
    },
  },
};
</script>

<style scoped>
.m-b-20 {
  margin-bottom: 20px !important;
}
</style>
