<template>
  <div class="log pl-2">
    <button
      target="_blank"
      type="button"
      class="track-ass-btn m-l-10"
      title="Details"
      :disabled="loading"
      @click.stop="check()"
    >
      Reopen
    </button>
    <!-- <a class="dropdowns-content" @click.stop="check()">Re-Open</a> -->

    <v-dialog v-model="dialog" max-width="30%">
      <v-form ref="form">
        <v-card class="comments-view">
          <v-toolbar fixed flat style="background-color: #fee9d0">
            <v-spacer></v-spacer>
            <v-toolbar-title>Re-Open Job</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.prevent="clearData">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-spacer class="response-pop">
            <v-layout style="justify-content: center">
              <!-- <v-btn-toggle v-model="toggle_exclusive" mandatory>
                
              </v-btn-toggle> -->
              <v-btn
                color="grey"
                :class="{ active: !toggle_exclusive }"
                style="text-transform: none; !important"
                @click="
                  toggle_exclusive = 0;
                  clear();
                "
                >Fully</v-btn
              >
              <v-btn
                color="grey"
                :class="{ active: toggle_exclusive }"
                style="text-transform: none; !important"
                @click="
                  toggle_exclusive = 1;
                  clear();
                "
                >Partially</v-btn
              >
            </v-layout>

            <v-card-text class="nospace">
              <v-layout pt-3 pb-0>
                <v-flex style="justify-content: center">
                  <div class="subheading">
                    BID MODEL -
                    <span style="color: black">Bid</span>
                  </div>
                </v-flex>
              </v-layout>
              <v-layout row pt-3>
                <v-flex xs12 md6>
                  <div class="subheading muted caption">EXPIRY DATE*</div>
                  <v-menu
                    ref="startDateBool"
                    lazy
                    v-model="startDateBool"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    full-width
                    :nudge-right="40"
                    min-width="290px"
                    :return-value.sync="startDate"
                  >
                    <v-text-field
                      slot="activator"
                      :label="x.showDate || 'Expiry Date *'"
                      required
                      v-model="startDate"
                      single-line
                      class="pt-0 currencyTitle"
                      prepend-icon="event"
                      readonly
                    ></v-text-field>
                    <v-date-picker
                      v-model="startDate"
                      class="minHeight"
                      :min="dateToday"
                      @change="$refs.startDateBool.save(startDate)"
                      no-title
                      scrollable
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex
                  class="pr-3 pl-4"
                  xs6
                  sm5
                  md5
                  style="position: relative"
                >
                  <div class="subheading muted caption">EXPIRY TIME*</div>
                  <svg
                    class="test10"
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                  >
                    <path
                      d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
                    />
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z" />
                  </svg>
                  <vue-timepicker
                    class="fonttime"
                    :minute-interval="30"
                    v-model="pickupTimes"
                    input-width="12em"
                    placeholder="Expiry Time*"
                    close-on-complete
                  ></vue-timepicker>
                </v-flex>

                <v-spacer />
              </v-layout>

              <v-layout pb-2>
                <v-flex>
                  <div class="subheading">
                    Job Price: {{ detail.jobPrice }}
                    {{ detail.transporterCurrency }}
                  </div>
                </v-flex>
                <v-spacer />
              </v-layout>
              <v-layout pb-2 v-if="this.toggle_exclusive == 1">
                <v-flex>
                  <div class="subheading" v-if="detail.amountForTransporter">
                    Bid Amount: {{ detail.amountForTransporter }}
                    {{ detail.transporterCurrency }}
                  </div>
                  <div class="subheading" v-else>Bid Amount - N.A</div>
                </v-flex>
                <v-spacer />
              </v-layout>
              <v-layout pb-2>
                <v-flex>
                  <div class="subheading">
                    Truck Required: {{ detail.requiredTrucks }}
                  </div>
                </v-flex>
                <v-spacer />
              </v-layout>
              <v-layout pb-2>
                <v-flex>
                  <div class="subheading">
                    Truck Remaining: {{ detail.remainingTruck }}
                  </div>
                </v-flex>
                <v-spacer />
              </v-layout>
              <v-layout pb-2 v-if="this.toggle_exclusive == 0">
                <v-flex>
                  <span
                    style="font-size: small"
                    v-if="detail.amountForTransporter"
                    >Previous bid Price: {{ detail.amountForTransporter }}
                    {{ detail.transporterCurrency }}</span
                  >
                  <div class="subheading" v-else>Previous Bid Price: N.A</div>
                </v-flex>
                <v-spacer />
              </v-layout>
              <v-layout v-if="this.toggle_exclusive == 0" pt-3>
                <v-flex>
                  <div class="subheading muted caption">ENTER BID PRICE*</div>
                  <div class="heading">
                    <v-text-field
                      label="Enter bid price*"
                      v-model="bidPrice"
                      required
                      type="number"
                      single-line
                      class="pt-0 currencyTitle"
                    />
                  </div>
                  <v-spacer />
                </v-flex>
              </v-layout>
              <div
                class="my-3"
                v-if="haserror"
                style="
                  color: red;
                  width: 90%;
                  border: 0px;
                  margin: auto;
                  text-align: center;
                "
              >
                {{ x.internalError }}
              </div>

              <v-layout style="text-align: center">
                <v-flex>
                  <v-btn
                    color="orange darken-1"
                    class="px-2 white--text"
                    text
                    style="text-transform: none; !important"
                    @click="open(detail.jobId, detail.jobPrice)"
                    >Confirm</v-btn
                  >
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-spacer>
          <v-dialog
            v-model="dialog1"
            persistent
            :max-width="options.width"
            :style="{ zIndex: options.zIndex }"
            @keydown.esc="cancel"
          >
            <loading :active.sync="loading" />
            <v-card>
              <v-toolbar dark :color="options.color" dense flat>
                <v-toolbar-title class="white--text">{{
                  title
                }}</v-toolbar-title>
              </v-toolbar>
              <v-card-text
                style="text-align: center"
                v-show="!!message"
                class="pa-4"
              >
                <v-layout class="justfy_c">
                  <div v-if="this.toggle_exclusive == 0" class="subheading">
                    {{ message }}
                  </div>
                  <div v-if="this.toggle_exclusive == 1" class="subheading">
                    {{ message1 }}
                  </div>
                </v-layout>
                <v-layout class="justfy_c">
                  <span style="color: lightgrey"
                    >Accepted Rate - {{ detail.jobPrice }}
                    {{ detail.transporterCurrency }}</span
                  >
                </v-layout>

                <v-layout class="justfy_c">
                  <span
                    style="color: lightgrey"
                    v-if="this.toggle_exclusive == 0"
                    >Bid Price - {{ this.bidPrice }}
                    {{ detail.transporterCurrency }}</span
                  >
                  <span
                    style="color: lightgrey"
                    v-if="this.toggle_exclusive == 1"
                    >Bid Price - {{ detail.amountForTransporter }}
                    {{ detail.transporterCurrency }}</span
                  >
                </v-layout>
                <v-layout class="justfy_c">
                  <span style="color: lightgrey"
                    >Truck Required: {{ detail.requiredTrucks }}</span
                  >
                </v-layout>
                <v-layout class="justfy_c">
                  <span style="color: lightgrey"
                    >Truck Remaining: {{ detail.remainingTruck }}</span
                  >
                </v-layout>
                <v-layout class="justfy_c">
                  <span style="color: lightgrey"
                    >Start Date - {{ getTimes(detail.startDate) }},
                    {{ detail.pickupTime }}</span
                  >
                </v-layout>
                <v-layout class="justfy_c">
                  <span style="color: lightgrey"
                    >Expiry Date- {{ getdate(this.startDate) }},
                    {{ this.pickupTimes.HH + ":" + this.pickupTimes.mm }}</span
                  >
                </v-layout>
              </v-card-text>

              <v-card-actions class="pt-0 justfy_c">
                <v-btn
                  small
                  style="text-transform: none; !important"
                  color="grey"
                  text
                  @click.native="cancel"
                  >No</v-btn
                >
                <v-btn
                  small
                  style="text-transform: none; !important"
                  color="orange"
                  darken-1
                  text
                  @click.native="agree"
                  >Yes</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
import { StorageKeys } from "../../constants/constants";
import { tenderSectionApiUrl } from "@/constants/api-urls.js";
import Loading from "vue-loading-overlay";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import "moment/locale/es"; // without this line it didn't work
moment.locale("en-in");
export default {
  created() {
    // this.getActivityLog();
  },
  components: {
    VueTimepicker,
    Loading,
  },
  data() {
    return {
      operation: "",
      operationName: "",
      dateToday: moment(new Date()).format("YYYY-MM-DD"),
      price: "",
      id: "",
      loading: false,
      toggleClass: false,
      toggle_exclusive: 0,
      bidPrice: "",
      pickupTimes: {
        HH: "",
        mm: "",
      },
      displayTime: "",
      startDate: "",
      startDateBool: false,
      x: {
        error: null,
        internalError: null,
      },
      options: {
        color: "#ffc04c",
        width: 400,
        zIndex: 200,
      },
      items: [],

      title: null,
      message: null,
      message1: null,
      cancelTitle: null,
      cancelMessage: null,
      dialog2: false,
      cancelComments: "",
      processing: false,
      reason: "",
      dialog: false,
      dialog1: false,
      disStatus: false,
      loading: false,
      rules: {
        required: (value) => !!value || "This field is required.",
      },
    };
  },
  props: {
    detail: Object,
  },

  watch: {},

  methods: {
    async check() {
      this.operationName = "reopen-job";
      this.loading = true;
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.dialog = true;
        this.loading = false;
      } else {
        this.loading = false;
        return;
      }
    },
    clear() {
      this.x.error = null;
      this.x.internalError = null;
      (this.bidPrice = ""),
        (this.startDate = ""),
        (this.pickupTimes = {
          HH: "",
          mm: "",
        });
    },

    jobModalChange(value) {
      if (value == "Fixed") {
        this.toggle_exclusive == 1;
        this.toggleClass = true;
      }
    },
    clearData() {
      this.dialog = false;
      (this.bidPrice = ""),
        (this.startDate = ""),
        (this.pickupTimes = {
          HH: "",
          mm: "",
        }),
        (this.x.error = null);
      this.x.internalError = null;
    },
    checkTime() {
      let re = /^\d{1,2}:\d{2}([ap]m)?$/;
      var time = this.pickupTimes.HH + ":" + this.pickupTimes.mm;
      if (time != "" && !time.match(re)) {
        this.x.internalError = "Please select valid expiry time.";
        time.focus();

        return false;
      }
    },
    haserror() {
      return this.x.error !== null;
    },
    getdate(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    getTimes(time) {
      return moment.unix(time).format("DD/MM/YYYY");
    },
    getTime(date) {
      return moment(date).format("ll LT");
    },
    open(val, price) {
      this.x.internalError = null;

      if (price < this.bidPrice) {
        this.x.internalError = "Bid amount should be less than job price";
        return false;
      }

      if (!this.startDate) {
        this.x.internalError = "Please select expiry date ";
        return false;
      }
      this.checkTime();
      if (!this.pickupTimes) {
        this.x.internalError = "Please select expiry time ";
        return false;
      }

      if (this.toggle_exclusive == 0) {
        if (!this.bidPrice.trim()) {
          this.x.internalError = "Please enter bid price amount";
          return false;
        }
        if (this.bidPrice < 1) {
          this.x.internalError = "Please enter valid bid amount";
          return false;
        }
      }

      if (!navigator.onLine) {
        this.loading = false;
        this.x.error = "Please check your internet connection";
        setTimeout(() => {
          this.x.error = "";
        }, 3000);
        return;
      }
      this.id = val;
      this.dialog = false;
      this.dialog1 = true;
      this.title = "Re-open Job";
      this.message = "Are you sure want to fully re-open this job?";
      this.message1 = "Are you sure want to partially re-open this job?";
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.reopen(this.id);
      // (this.startDate = ""), (this.bidPrice = ""), (this.dialog1 = false);
      // this.pickupTimes = {
      //   HH: "",
      //   mm: ""
      // };
    },
    cancel() {
      this.resolve(false);
      this.dialog1 = false;
    },
    async reopen(value) {
      this.loading = true;

      this.operationName = "reopen-job";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        let url = tenderSectionApiUrl.reopenBid;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };

        let body = {
          jobId: value.toString(),
        };
        if (this.toggle_exclusive == 0) {
          body.type = "fully";
        }
        if (this.toggle_exclusive == 1) {
          body.type = "partial";
        }
        var time = this.pickupTimes.HH + ":" + this.pickupTimes.mm;

        var tempTime = new Date(this.startDate);
        var year = tempTime.getFullYear();
        var month = tempTime.getMonth() + 1;
        var day = tempTime.getDate();

        var start_date = new Date(year + "-" + month + "-" + day + " " + time);

        start_date = new Date(start_date).toISOString();

        if (this.startDate && time) {
          body.expiryDateTime = start_date;
        }
        if (this.bidPrice && this.toggle_exclusive == 0) {
          body.newBidAmount = this.bidPrice;
        }

        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            this.$emit("refreshJob", this.detail);
            this.clearData();
            this.dialog4 = false;
            this.loading = false;
          },
          (error) => {
            this.$emit("reopenError", error.response.data.message);
            this.loading = false;

            this.x.error = error.response.data.message;
          }
        );
      } else {
        return;
      }
    },
  },
};
</script>
<style scoped>
.dropdowns-content a {
  color: black;
  padding: 5px 5px 5px 5px;
  text-decoration: none;
  display: block;
}

.dropdowns-content a:hover {
  background-color: #ddd;
}
.break_long_text {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.comments-view .v-toolbar {
  width: 100%;
  /* margin-left: 25%; */
}
.v-toolbar--fixed {
  position: inherit;
}
.view-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #c87ffe;
  border: 2px solid indigo;
  padding: 2px 5px;
  border-radius: 5px;
}
.fonttime {
  font-size: 15px;
  border: none;
  outline: none;
}

.test10 {
  position: absolute;
  fill: rgba(0, 0, 0, 0.54);
  left: 0px;
  top: 20px;
  margin: 0 !important;
}
.response-pop {
  padding: 40px;
}
.active {
  background-color: orange !important;
  color: #ffffff !important;
}
.yellow-button {
  border-radius: 5px;
  background-color: #ffd65c;
  color: #ffffff !important;
  border: 2px solid #ffc107 !important;
}
</style>
